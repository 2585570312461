import React, { useContext, useState } from 'react';
import { scrollTo, getTextColorClass, getColor } from '@utils/Utils';
import { BasicData } from '@App';

import './style.scss';
import { useScrollPosition } from '@utils/hooks/useScrollPosition';

const BackToTop = () => {
    const { theme } = useContext(BasicData);
    const [show, setShow] = useState(false);

    const colors = getColor(theme);

    useScrollPosition(({currPos}) => {
        if (currPos.y > 0) {
         setShow(true)
        } else {
         setShow(false)
        }
 
     }, [show], null, true)

    return (
        <div style={{borderColor : getTextColorClass(colors?.primary) === 'light-text' ? '#FFF' : '#5E5873'}} onClick={() => scrollTo()} className={`back-to-top${show ? ' show' : ''}`}>
            <i className={`icon-chevrons-up ${getTextColorClass(colors?.primary)}`}></i>
        </div>
    )
}

export default BackToTop